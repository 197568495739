'use client';

import { useRouter, useSearchParams } from 'next/navigation';

import MaxWidthContainer from '@/components/dom/max-width-container';
import { LoginSignupForm, OpenWithTypes } from '@/components/forms/login-signup-form';
import { useGlobalState } from '@/components/global/global-state';
import { REDIRECT_QUERY_PARAM } from '@/lib/constants/general';

export const LoginPage = () => {
    const params = useSearchParams();
    const { push } = useRouter();
    const { globalState } = useGlobalState();
    const { currentUserLoading } = globalState;

    if (currentUserLoading) {
        return <></>;
    }

    const onSuccess = () => {
        const redirectPath = params?.get(REDIRECT_QUERY_PARAM);

        redirectPath && push(redirectPath);
    };

    return (
        <MaxWidthContainer className="py-12">
            <div className="w-1/2 mx-auto">
                <LoginSignupForm
                    {...{
                        allowSocialLogin: true,
                        onSuccess,
                        openWith: OpenWithTypes.LOGIN,
                        trackingProperties: {},
                    }}
                />
            </div>
        </MaxWidthContainer>
    );
};
